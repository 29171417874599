import './bootstrap';

const registerAnchorHashListener = () => {
  document.querySelectorAll('a').forEach((anchor) => {
    anchor.addEventListener('click', (event) => {
      const href = anchor.getAttribute('href');
      if (href.startsWith('#') || href.startsWith('/#')) {
        const target = document.querySelector(href.replace('/#', '#'));

        if (target) {
          event.preventDefault();
          target.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  });
};

const registerMenuListener = () => {
  const fn = () => {
    const header = document.querySelector('#header > div');
    if (header) {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        if (window.innerWidth < 1024) {
          header.classList.add('shadow', 'bg-white');
        } else header.classList.add('bg-gray-200/50');
      } else if (window.innerWidth < 1024) {
        header.classList.remove('shadow');
      } else header.classList.remove('bg-gray-200/50');
    }
  };

  window.addEventListener('scroll', fn);
  fn();
};

const registerScrollToTopButton = () => {
  const fn = () => {
    const button = document.querySelector('.scroll-to-top');
    if (button) {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        button.classList.remove('hidden');
      } else {
        button.classList.add('hidden');
      }

      button.addEventListener('click', (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    }
  };
  window.addEventListener('scroll', fn);
  fn();
};

registerMenuListener();
registerAnchorHashListener();
registerScrollToTopButton();
